<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/billing" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Fatura Bilgileri</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Kişi/Kurum Adı</label>
              <input
                type="text"
                class="form-control"
                name="account_title"
                :value="billingGet[0].account_title"
                placeholder="Kişi/Kurum Adı"
              />
            </div>
          </div>
          <div class="col-md-12 mb-1">
            <label class="form-label">Şirket Türü</label>
          </div>
          <div class="col-md-6 mb-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="company_type"
                value="1"
                :checked="billingGet[0].company_type==1?true:false"
                id="flexRadioDefault1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Şahıs Şirketi
              </label>
            </div>
          </div>

          <div class="col-md-6 mb-2">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="company_type"
                value="2"
                :checked="billingGet[0].company_type==2?true:false"
                id="flexRadioDefault2"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Limited/Anonim Şirket
              </label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Vergi Kimlik Numarası</label>
              <input
                type="text"
                class="form-control"
                name="tax_number"
                :value="billingGet[0].tax_number"
                placeholder="Vergi Kimlik Numarası"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label">Vergi Dairesi</label>
              <input
                type="text"
                class="form-control"
                name="tax_office"
                :value="billingGet[0].tax_office"
                placeholder="Vergi Dairesi"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Kayıtlı Adreslerim</label>
              <select
                name="addresslist"
                class="form-select"
                aria-label="Default select example"
                @change="adressSelect"
              >
                <option selected>Kayıtlı adreslerimden getir</option>

                <option
                  v-for="(item, i) in addressList"
                  :key="'option_' + i"
                  :value="JSON.stringify(item)"
                >
                  {{ item.address_title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Adres</label>

              <textarea
                type="text"
                class="form-control"
                name="address"
                placeholder="Adres"
                :value="billingGet[0].address"
              ></textarea>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              
              <label class="form-label">İl</label>

              <select
                name="city"
                id="city"
                class="form-control"
                @change="cityChange"
              >
                <optgroup label="Şehir Seçiniz">
                  <option
                    v-for="(item, i) in cityList"
                    :key="i"
                    :selected="
                      selectedCity == item.name
                        ? districts(item.districts)
                        : false
                    "
                    :value="item.name"
                    :data-province="JSON.stringify(item.districts)"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">İlçe</label>
              <select name="province" id="province" class="form-control">
                <optgroup label="İlçe Seçiniz">
                  <option
                    v-for="(item, i) in selectedDistricts"
                    :key="i"
                    :selected="selectedProvince == item.name ? true : false"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary"
        >
          Fatura Bilgilerini Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title = "Fatura Bilgilerim";
    this.$store.dispatch("billingAddressGet").then((value) => {
      this.billingGet = value;
      this.selectedCity = value[0].city;
      this.selectedProvince = value[0].province;
    });
    this.$store.dispatch("addressListGet").then((value) => {
      this.addressList = value;
    });
    if (this.cityList.length == 0) {
      let cityInfo = {};
      this.$store.dispatch("citysGet", cityInfo).then((value) => {
        this.cityList = value.list;
        this.selectedDistricts = value.list[0].districts;
      });
    }
  },
  data() {
    return {
      billingGet: [],
      addressList: [],
      pageGet: [],
      cityList: [],
      selectedCity: "",
      selectedProvince: "",
      selectedDistricts: [],
    };
  },
  methods: {
    adressSelect() {
      let item = document.querySelector("select[name=addresslist]").value;
      let data = JSON.parse(item);
      document.querySelector("textarea[name=address]").value = data.address;
      this.selectedCity = data.city;
      this.selectedProvince = data.province;
      this.cityChange;
    },
    cityChange() {
      var element = document.getElementById("city");
      var option_doj =
        element.options[element.selectedIndex].getAttribute("data-province");
      this.selectedDistricts = JSON.parse(option_doj);
    },
    districts(item) {
      this.selectedDistricts = item;
      return true;
    },
    save() {
      let account_title = document.querySelector(
        "input[name=account_title]"
      ).value;
      let company_type = document.querySelector(
        "input[name=company_type]"
      ).value;
      let tax_number = document.querySelector("input[name=tax_number]").value;
      let tax_office = document.querySelector("input[name=tax_office]").value;
      let address = document.querySelector("textarea[name=address]").value;
      let city = document.querySelector("select[name=city]").value;
      let province = document.querySelector("select[name=province]").value;
      if (account_title == null || account_title == "") {
        this.$vToastify.warning("Fatura başlığı boş bırakılamaz", "Uyarı!");
      } else if (company_type == null || company_type == "") {
        this.$vToastify.warning("Şirket türü seçmediniz", "Uyarı!");
      } else if (tax_number == null || tax_number == "") {
        this.$vToastify.warning("Vergi numarası boş olamaz", "Uyarı!");
      } else if (tax_office == null || tax_office == "") {
        this.$vToastify.warning("Vergi dairesi boş geçilemez", "Uyarı!");
      } else if (address == null || address == "") {
        this.$vToastify.warning("Adres boş geçilemez", "Uyarı!");
      } else if (city == null || city == "") {
        this.$vToastify.warning("Şehir boş bırakılamaz", "Uyarı!");
      } else if (province == null || province == "") {
        this.$vToastify.warning("Bölge boş bırakılamaz", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let addressSave = {
          account_title: account_title,
          company_type: company_type,
          tax_number: tax_number,
          tax_office: tax_office,
          address: address,
          city: city,
          province: province,
        };
        this.$store.dispatch("billingSave", addressSave).then((value) => {
          this.$vToastify.success("Fatura bilgileri kaydedildi", "Başarılı!");
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML =
            "Fatura Bilgilerini Kaydet";
        });
      }
    },
  },
  components: {},
  mounted() {},
};
</script>